
  import { Vue, Component } from "vue-property-decorator";
  import dayjs from "dayjs";
  import { upload } from "@/api/modules/getmachine";
  @Component({
    name: "Eqmanagement",
  })
  export default class Eqmanagement extends Vue {
    private tableData: Array<any> = [];
    private pageNo = 1;
    private pageSize = 10;
    private form = {
    blue_name: "", 
    mac_code: "", 
};
    
    
  
    private async mounted() {
    //   let datas = [];
    //   for (let i = 0; i < 400; i++) {
    //     datas.push({
    //       date: dayjs().format("YYYY-MM-DD"),
    //       name: `姓名${i}`,
    //       address: `地址${i}`,
    //     });
    //   }
    //   this.tableData = datas;
      // let res =await getMachineNum(1)
      // this.tableData = res.list;
      // this.pageSize = res.total
      // //this.pageNo = res.page_count
      // console.log("默认第一页",res)
    }
  
    private handleSizeChange(val: number) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.pageNo = 1;
    }
  
    private handleCurrentChange(val: number) {
      console.log(`当前页: ${val}`);
      this.pageNo = val;
    }
  
    //打开二维码
    // private async openQrcode(id:string){
    //   let res = await getMachineQrcode(id)
    //   let url = res.qrcode_url
    //   console.log(url)
    //   this.$alert('<img style="width: 200px; height: 200px; margin-left: 83px;" src='+url+'></img>', '二维码', {
    //       confirmButtonText: '确定',
    //       dangerouslyUseHTMLString:true,
    //       callback: action => {
    //       }
    //     });
    // }

    private async onSubmit() {
      if(!this.form.blue_name&&!this.form.mac_code){
        this.$message({
          message: 'mac地址和设备名称不可以为空',
          type: 'warning'
        });
        return
      }
      let res =await upload(this.form);
      console.log("res",res);
      if(res){
        this.$message({
          message: '上传设备成功',
          type: 'success'
        });
        this.form={
         blue_name:"",
         mac_code:"" 
        }
      }else{
        this.$message({
          message: '上传设备失败，请联系后台管理员',
          type: 'error'
        });
      }
    }
  }
  